import api from '@/services/api';
import localStorage from '@/components/Services/storage';

export default {
    /**
     * Get user main data when user log in
     *
     * @param context
     */
    getUserAccounts(context) {
        api.get('/api/me')
            .then((response) => {
                context.commit('SET_USER_CONTRACTS', {
                    contracts: response.data.body.user.contracts,
                });
            })
            .catch(() => {
                // Token expired or invalid token
                localStorage.remove('token');
            });
    },

    /**
     * On click in float box menu, change chosen contract
     * @param context
     * @param payload
     */
    setClickedContract(context, payload) {
        // If contract is expanded and clicked again, dont send again api call
        if (context.getters.contract.clickedContract.contractId === payload.contractId) {
            return;
        }

        // On change contract, update data for that contract
        context.dispatch('getUserAccounts');
        context.dispatch('setResources', {}, { root: true });

        context.commit('SET_CLICKED_CONTRACT', {
            contract: payload,
        });

        context.dispatch('setGdpr', {}, { root: true });
    },
};
