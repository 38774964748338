<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">
                <h2 class="title" v-if="oldTariff && Object.keys(oldTariff).length">{{ $t('pages.tariffs.oldTariffTitle') }}</h2>
                <div class="row"
                     v-if="Object.keys(oldTariff).length && oldTariff.label === 'PREPAID' && oldTariff.name !== 'Mtel Free'"
                >
                    <p></p>
                </div>
                <div class="row"
                     v-else-if="!Object.keys(oldTariff).length && !tariffs.length"
                >
                    <p>
                        {{ $t('pages.tariffs.noTariffs') }}
                    </p>
                </div>
                <div class="row" v-if="oldTariff && Object.keys(oldTariff).length">
                    <div class="col-md-6 col-xl-4" :key="oldTariff.name">
                        <div class="package-item">
                            <ul class="old-tariff-list">
                                <li class="tariff-info">
                                    <span class="icon icon-new-uredjaji"></span>
                                    <p class="feature-name">{{ oldTariff.name.toUpperCase() }}</p>
                                </li>
                            </ul>
                            <footer>
                                <p class="price">
                                    {{ oldTariff.price.value }} {{ oldTariff.price.label }}
                                    <span class="currency">{{ oldTariff.durationLabel }}</span>
                                </p>
                                <button class="red-button disable-tariff-button"
                                        disabled
                                >
                                    {{ $t('pages.tariffs.buttons.deactivate') }}
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>
                <h2 class="title">{{ $t('pages.tariffs.title') }}</h2>
                <div class="row">
                    <div class="col-md-6 col-xl-4" v-for="tariff in tariffs" :key="tariff.name">
                        <div class="package-item">
                            <h3>{{ tariff.name.toUpperCase() }}</h3>
                            <ul>
                                <li class="tariff-info" v-for="item in tariff.descriptionItems" :key="item.title">
                                    <span class="icon icon-new-uredjaji" v-if="item.itemType === 1"></span>
                                    <span class="icon icon-new-mobilni-internet" v-else></span>
                                    <p class="feature-name">{{ item.title }}</p>
                                    <p class="feature-desc">{{ item.subtitle }}</p>
                                </li>
                            </ul>
                            <footer>
                                <p class="price">
                                    {{ tariff.price.value }} {{ tariff.price.label }}
                                    <span class="currency">{{ tariff.durationLabel }}</span>
                                </p>
                                <button class="red-button"
                                        v-if="tariff.isActive"
                                        @click="deactivateTariff(tariff.mvno_code)"
                                >
                                    {{ $t('pages.tariffs.buttons.deactivate') }}
                                </button>
                                <button class="red-button"
                                        v-else
                                        @click="openConfirmationModal(tariff.mvno_code)"
                                >
                                    {{ $t('pages.tariffs.buttons.activate') }}
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>
                <div class="row"
                     v-if="insufficientFounds"
                >
                    <p>
                        {{ $t('pages.tariffs.insufficientFounds') }}
                        <router-link to="/recharge-credit">{{ $t('pages.rechargeCredit.name') }}</router-link>
                    </p>
                </div>
                <div class="row"
                     v-if="positiveFoundMtelFree"
                >
                    <p>
                        {{ $t('pages.tariffs.positiveFoundsMtelFree') }}
                        <router-link to="/additional-packages">{{ $t('pages.tariffs.here') }}</router-link>
                    </p>
                </div>

                <InsufficientFoundModal
                    @close="closeModal"
                    @redirectAccepted="redirectAccepted"
                    v-if="showInsufficientFoundModal"
                />

                <ConfirmationModal
                    v-if="isTariffButtonClicked"
                    @close="closeConfirmationModal"
                    @paymentAgree="sendTariffNameAndOpenModalForm"
                />
            </main>
        </div>
    </section>
</template>

<script>
import Tariffs from './Tariffs';
export default Tariffs;
</script>
