import ConfirmationModal from '@/components/Partials/ConfirmationModal/ConfirmationModal.vue';
import InsufficientFoundModal from '@/components/Partials/InsufficientFoundModal/InsufficientFoundModal.vue';
import router from '@/router';
import api from '@/services/api';
import { mapActions, mapGetters } from 'vuex';
import { store } from '@/store/store';

export default {
    data() {
        return {
            tariffs: [],
            showInsufficientFoundModal: false,
            mvnoCode: null,
            isTariffButtonClicked: false,
            oldTariff: {},
            brandName: process.env.VUE_APP_BRAND_NAME,
        };
    },

    components: {
        ConfirmationModal,
        InsufficientFoundModal,
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            contract: 'contract/contract',
            resources: 'resources',
        }),

        insufficientFounds() {
            return Object.keys(this.oldTariff).length
                && Object.keys(this.resources).length
                && this.oldTariff.label === 'PREPAID'
                && parseFloat(this.oldTariff.price.value) > parseFloat(this.resources.balance.value)
                && this.oldTariff.name === 'Mtel Free';
        },

        positiveFoundMtelFree() {
            return Object.keys(this.oldTariff).length
                && Object.keys(this.resources).length
                && this.oldTariff.label === 'PREPAID'
                && parseFloat(this.oldTariff.price.value) < parseFloat(this.resources.balance.value)
                && this.oldTariff.name === 'Mtel Free';
        },
    },

    watch: {
        /**
         * When language is changed, then call again api call with new locale
         */
        locale() {
            this.getTariffs();
        },
        /**
         * Listen for object changed for clicked contract
         */
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.getTariffs();
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions('user', ['setUserDataAfterUpdating']),

        /**
         * Get all tariffs on component mounted
         */
        getTariffs() {
            this.$Progress.start();
            api.get('/api/tariffs')
                .then((response) => {
                    this.tariffs = response.data.body.tariffs;
                    this.oldTariff = response.data.body.oldTariff;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Deactivate tariff
         * @param id
         */
        deactivateTariff(id) {
            api.post(`/api/tariffs/deactivate/${id}`)
                .then((response) => {
                    if (response.data.code !== 'DEACTIVATE_PLAN_SUCCESS') {
                        this.$toast.error(this.$i18n.t('messages.error'));
                        this.$Progress.finish();
                        return;
                    }
                    this.tariffs.find((tariff) => tariff.mvno_code === id).isActive = false;
                    this.$Progress.finish();
                    this.$toast.open(this.$i18n.t('messages.success'));
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Send Tariff NAME to our server then
         */
        sendTariffNameAndOpenModalForm() {
            this.isTariffButtonClicked = false;
            const params = new URLSearchParams();
            params.append('type', 'tariff');
            params.append('plan_id', this.mvnoCode);
            api.post(`/api/tariffs/activate/${this.mvnoCode}`, params)
                .then((response) => {
                    this.tariffs.find((tariff) => tariff.mvno_code === this.mvnoCode).isActive = true;
                    this.$Progress.finish();
                    this.$toast.success(this.$i18n.t('messages.success'));
                    this.getTariffs();
                    this.setUserDataAfterUpdating();
                    store.dispatch('setResources', response.data.body.user);
                }).catch((error) => {
                    if (error.response.data.code === 'ACTIVATE_PLAN_FAILED_INSUFFICIENT_FOUND') {
                        this.showInsufficientFoundModal = true;
                    } else {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    this.$Progress.finish();
                });
        },

        /**
         * Close showInsufficientFoundModal modal
         */
        closeModal() {
            this.showInsufficientFoundModal = false;
        },

        /**
         * Open confirmation modal
         */
        openConfirmationModal(mvnoCode) {
            this.mvnoCode = mvnoCode;
            this.isTariffButtonClicked = true;
        },

        /**
         * Close confirmation modal
         */
        closeConfirmationModal() {
            this.isTariffButtonClicked = false;
        },

        /**
         * Redirect user to recharge-credit page
         */
        redirectAccepted() {
            return router.push('recharge-credit');
        },
    },

    mounted() {
        this.getTariffs();
    },
};
