import * as types from '@/store/mutation-types';
import actions from '@/store/actions/contractActions';

/**
 * Define the module state
 *
 * @type {object}
 */
const moduleState = {
    clickedContract: {},
    contracts: [],
    phoneNumber: '',
    contractType: '',
};

const mutations = {
    [types.SET_USER_CONTRACTS](state, userData) {
        state.contracts = userData.contracts;
        if (!Object.keys(state.clickedContract).length) {
            state.clickedContract = userData.contracts[0];
        }
    },

    [types.SET_CLICKED_CONTRACT](state, userData) {
        state.clickedContract = userData.contract;
    },

    [types.CLEAR_CONTRACT_DATA](state) {
        state.clickedContract = {};
        state.contracts = [];
        state.phoneNumber = '';
        state.contractType = '';
    },
};

/**
 * Define the module getters
 *
 * @type {object}
 */
const getters = {
    contract: (state) => state,
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: moduleState,
};
