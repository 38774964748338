<template>
  <footer class="container mt-auto footer-fixed-bottom">
    <nav class="navbar navbar-expand-lg navbar-light navbar-main flex-wrapper">
      <div>
          <router-link :to="'/'" class="navbar-brand logo">
            <img
                        v-if="brandName === 'viola'"
                        src="../../assets/viola-logo-white.svg"
                        alt="Viola logo"
                    />
            <img v-else src="../../assets/logo-white.png" alt="footer logo">
          </router-link>
        <p> {{ $t('footer.logoText') }}</p>
      </div>
      <ul class="ml-auto footer-list">
        <li class="footer-internal-link">
            <a :href="storeLocationsUrl" target="_blank">
                {{ $t('footer.links.storeLocations') }}
            </a>
        </li>
        <li class="footer-internal-link">
            <a :href="faqUrl" target="_blank">
                {{ $t('footer.links.faq') }}
            </a>
        </li>
        <li class="footer-internal-link">
            <a :href="supportCalLUsUrl" target="_blank">
                {{ $t('footer.links.supportCalLUs') }}
            </a>
        </li>
        <li class="footer-internal-link">
            <a :href="supportMailUsUrl" target="_blank">
                {{ $t('footer.links.supportMailUs') }}
            </a>
        </li>
        <li class="footer-internal-link">
            <a :href="websiteUrl" target="_blank">
                {{ $t('footer.links.website') }}
            </a>
        </li>
        <li class="footer-social-link">
            <a :href="facebookUrl" target="_blank">
                <img v-if="brandName === 'viola'" src="../../assets/images/social-icons/FB.svg" alt="facebook logo">
                <img v-else src="../../assets/images/social-icons/fb-icon.png" alt="facebook logo">
            </a>
        </li>
        <li class="footer-social-link">
            <a :href="youtubeUrl" target="_blank">
                <img v-if="brandName === 'viola'" src="../../assets/images/social-icons/YouTube.svg" alt="youtube logo">
                <img v-else src="../../assets/images/social-icons/yt-icon.png" alt="youtube logo">
            </a>
        </li>
        <li class="footer-social-link">
            <a :href="instagramUrl" target="_blank">
                <img v-if="brandName === 'viola'" src="../../assets/images/social-icons/Instagram.svg" alt="instagram logo">
                <img v-else src="../../assets/images/social-icons/insta-icon.png" alt="instagram logo">
            </a>
        </li>
        <li v-if="brandName === 'viola'" class="footer-social-link">
            <a :href="tiktokUrl" target="_blank">
                <img  src="../../assets/images/social-icons/tiktok.svg" alt="tiktok logo">
            </a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import Footer from './Footer';
export default Footer;
</script>
