import { mapGetters, mapActions } from "vuex";
import NewsSideBar from "@/components/Notification/NewsSideBar.vue";

export default {
    data() {
        return {
            isActiveNewsMenu: false,
            isActiveHamburgerMenu: false,
            brandName: process.env.VUE_APP_BRAND_NAME,
        };
    },
    components: {
        NewsSideBar,
    },

    computed: {
        ...mapGetters({
            user: "user/user",
            notification: "notification/notification",
            unreadNotification: "notification/unreadNotification",
            locale: "locale",
            contract: "contract/contract",
        }),
        // Return true or false for is user logged in
        auth() {
            return this.user.isLoggedIn;
        },
    },

    /**
     * This will close navigation side bar on route change
     */
    watch: {
        "$route.path": function () {
            this.isActiveHamburgerMenu = false;
        },
    },

    methods: {
        ...mapActions(["changeLanguage"]),
        // Toggle bell icon for news in navigation
        toggleNewsMenu() {
            this.isActiveNewsMenu = !this.isActiveNewsMenu;
        },
        // Toggle navigation hamburger on mobile
        toggleNavHamburger() {
            this.isActiveHamburgerMenu = !this.isActiveHamburgerMenu;
        },

        /**
         * Change language of whole app
         * @param language
         */
        changeLanguageApp(language) {
            this.$i18n.locale = language;
            this.changeLanguage(language);
            this.$toast.success(this.$i18n.t("messages.success"));
        },

        /**
         * Show modal when user click logout button in navigation
         */
        logOutUser() {
            this.$emit("logoutClicked", true);
        },
    },
};
