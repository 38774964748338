import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store/store';
import Tariffs from '@/components/Tariffs/Tariffs.vue';
import Tv from '@/components/Tv/Tv.vue';
import RechargeCredit from '@/components/RechargeCredit/RechargeCredit.vue';
import CustomerSupport from '@/components/CustomerSupport.vue';
import Bills from '@/components/Bills.vue';
import Login from '@/components/Authentication/Login.vue';
import Listing from '@/components/Listing/Listing.vue';
import Notification from '@/components/Notification/Notification.vue';
import Administration from '@/components/Administration/Administration.vue';
import Resources from '@/components/Resources/Resources.vue';
import PaymentThankYouPage from '@/components/PaymentThankYouPage/PaymentThankYouPage.vue';
import PaymentThankYouPagePayingBill from '@/components/PaymentThankYouPage/BillStatus/PaymentThankYouPagePayingBill.vue';
import Devices from '@/components/Devices/Devices.vue';
import NotFound from '@/components/404/NotFound.vue';
import MobilePaymentView from '@/components/PaymentMobileApp/MobilePaymentView.vue';
import PaymentThankYouPageMobile from '@/components/PaymentMobileApp/ThankYouPage/PaymentThankYouPageMobile.vue';
import TvTariffs from '@/components/Tv/TvTariffs/TvTariffs.vue';
import localStorage from '@/components/Services/storage';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Resources,
        name: 'resources',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/tv',
        component: Tv,
        name: 'tv',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/additional-packages',
        name: 'packages',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: 'about' */ '@/components/AdditionalPackages/AdditionalPackages.vue'),
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/recharge-credit',
        component: RechargeCredit,
        name: 'rechargeCredit',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/customer-support',
        component: CustomerSupport,
        name: 'customerSupport',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/Tariffs',
        component: Tariffs,
        name: 'tariffs',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/tv-tariffs',
        component: TvTariffs,
        name: 'tvTariffs',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/bills',
        component: Bills,
        name: 'bills',
        meta: {
            onlyIfAuthenticated: true,
        },
    },
    {
        path: '/listing',
        component: Listing,
        name: 'listing',
    },
    {
        path: '/notification/:id',
        component: Notification,
        name: 'notification',
    },
    {
        path: '/administration',
        component: Administration,
        name: 'administration',
    },
    {
        path: '/transaction-completed',
        component: PaymentThankYouPage,
        name: 'paymentThankYouPage',
    },
    {
        path: '/transaction-completed-mobile',
        component: PaymentThankYouPageMobile,
        name: 'paymentThankYouPageMobile',
    },
    {
        path: '/transaction-completed-bill-paying',
        component: PaymentThankYouPagePayingBill,
        name: 'paymentThankYouPagePayingBill',
    },
    {
        path: '/device',
        component: Devices,
        name: 'devices',
    },
    {
        path: '/mobile-payment',
        component: MobilePaymentView,
        name: 'mobilePaymentView',
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
    },
    {
        path: '/404',
        component: NotFound,
        name: 'notFound',
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active-link',
    mode: 'history',
    routes,
});

const handleLogin = function handleLogin(to, from, next) {
    /**
     * if user is coming from mobile app
     */
    if (to.path === '/mobile-payment') {
        return next({
            path: `/mobile-payment?checkoutId=${to.query.checkoutId}`,
        });
    }
    /**
     * if user is coming from mobile app
     */
    if (to.path === '/transaction-completed-mobile') {
        return next({
            path: `/transaction-completed-mobile?resourcePath=${to.query.resourcePath}`,
        });
    }
    // Gets API token from storage if any
    const token = localStorage.get('token', true);
    const userLoggedIn = store.state.user.isLoggedIn;

    // If user is not logged in but token exist in local storage,
    // check if token is still valid and try to auto login him back
    if (token && !store.state.user.isLoggedIn) {
        console.log('try auto login user from router index.js');
        store.dispatch('notification/getAllNotifications', { token });
        store.dispatch('user/tryAutoLogin', { token }).then(() => next());
        store.dispatch('setResources');
    }
    // If user is not logged in and token doesnt exist, redirect him on login page
    if (!token && !userLoggedIn) {
        return next({
            path: '/login',
        });
    }

    /**
     * Check if user has token and he is trying to go on login page
     * If so, then redirect him instantly on home page
     */
    if (to.path === '/login') {
        if (token) {
            return next({
                path: '/',
            });
        }
    }
    // If user is logged in and token exist, let him go
    return next();
};

/**
 * Middleware to check auth before each route
 *
 * @return  {void}
 */
router.beforeEach((to, from, next) => {
    // TODO fiksati kada korisnik je ulogovan i pokusa da ode na login
    if (to !== '/login') {
        next();
    }
    handleLogin(to, from, next);
});

export default router;
