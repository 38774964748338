export default {
    props: ["account"],

    data() {
        return {
            description: false,
            width: "width: 0",
            brandName: process.env.VUE_APP_BRAND_NAME,
        };
    },

    methods: {
        animation() {
            setTimeout(() => {
                this.width = `width:${Number(
                    ((this.account.balance / this.account.initialBalance) * 100).toFixed(1)
                )}%`;
            }, 1500);
        },
        /**
         * Show tooltip description
         */
        showDescription() {
            this.description = true;
        },

        /**
         * Hide tooltip description
         */
        hideDescription() {
            this.description = false;
        },
    },

    mounted() {
        this.animation();
    },
};
