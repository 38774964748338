import api from '@/services/api';
import router from '@/router/index';
import localStorage from '@/components/Services/storage';
import { store } from '@/store/store';

export default {
    /**
     * Attempts to login a user via MSISDN login type
     *
     * @param   {Object}  state
     * @param   {Object}  commit
     * @param   {Object}  authData
     * @return  {void}
     */
    attemptToGetSmsCodeViaMsisdn({ commit }, authData) {
        const params = new URLSearchParams();
        params.append('phone', authData.phone);
        api.post('/api/send-sms', params)
            .then((response) => {
                const isSmsCodeSent = response.data.code === 'SEND_SMS_TO_USER_SUCCESS';

                commit('GET_SMS_CODE', {
                    userId: response.data.body.user.userId,
                    isSmsCodeSent,
                    phoneNumber: response.data.body.user.phoneNumber,
                });
            })
            .catch((error) => {
                if (error.response.data.code === 'SEND_SMS_TO_USER_FAILED_USER_DOESNT_EXIST') {
                    commit('SHOW_LOGIN_ERRORS', {
                        body: {
                            validation_errors:
                                {
                                    phone: [error.response.data.body.message],
                                },
                        },
                    });
                } else if (error.response.data.code === 'REQUEST_FAILED_SERVER_ERROR') {
                    commit('SHOW_LOGIN_ERRORS', {
                        body: {
                            validation_errors:
                                {
                                    phone: [error.response.data.body.message],
                                },
                        },
                    });
                } else {
                    commit('SHOW_LOGIN_ERRORS', error.response.data);
                }
            });
    },

    /**
     * Attempts to login a user via UserName and Password
     *
     * @param context
     * @param {object} authData
     */
    loginViaPassword(context, authData) {
        const params = new URLSearchParams();
        params.append('username', authData.username);
        params.append('password', authData.password);
        api.post('/api/password-login', params)
            .then((response) => {
                const token = response.data.body.token;
                context.commit('STORE_USER', {
                    token,
                });
                localStorage.save('token', token, true);
                // Check contract type and redirect user to home page or tv page
                context.commit('CHECK_FIRST_CONTRACT_TYPE');
            })
            .catch((error) => {
                context.commit('SHOW_LOGIN_ERRORS', error.response.data);
            });
    },

    /**
     * Attempts to login a user via Sms code login type
     *
     * @param context
     * @param {object} authData
     */
    loginViaSms(context, authData) {
        const params = new URLSearchParams();
        params.append('id', authData.userId);
        params.append('code', authData.code);
        api.post('api/sms-login', params)
            .then((response) => {
                const token = response.data.body.token;
                context.commit('STORE_USER', {
                    token,
                });
                localStorage.save('token', token, true);
                // Check contract type and redirect user to home page or tv page
                context.commit('CHECK_FIRST_CONTRACT_TYPE');
            })
            .catch((error) => {
                if (error.response.data.code === 'SMS_LOGIN_USER_FAILED_INVALID_CODE') {
                    context.commit('SHOW_LOGIN_ERRORS', {
                        body: {
                            validation_errors:
                                {
                                    code: [error.response.data.body.message],
                                },
                        },
                    });
                } else {
                    context.commit('SHOW_LOGIN_ERRORS', error.response.data);
                }
            });
    },

    /**
     * Attempts to logout user
     *
     * @param context
     */
    logout(context) {
        context.commit('CLEAR_AUTH_DATA');
        context.commit('contract/CLEAR_CONTRACT_DATA', {}, { root: true });
        localStorage.clear();
        router.replace('/login');
    },

    /**
     * Checks if user is logged in (has valid token)
     *
     * @param state
     * @param payload
     * @return  {void}
     */
    tryAutoLogin({ commit }, payload) {
        api.get('/api/me')
            .then(() => {
                commit('STORE_USER', {
                    token: payload.token,
                });
            })
            .catch(() => {
                // Token expired or invalid token
                localStorage.remove('token');
            });
    },

    /**
     * Get user main data when user log in
     *
     * @param context
     */
    getUserData(context) {
        api.get('/api/me')
            .then((response) => {
                context.commit('STORE_MAIN_USER_DATA', {
                    data: response.data.body.user,
                });
                context.commit('contract/SET_USER_CONTRACTS', {
                    contracts: response.data.body.user.contracts,
                }, { root: true });

                context.commit('notification/SET_NUMBER_UNREAD_NOTIFICATION', {
                    number: response.data.body.user.unreadNotifications,
                }, { root: true });
            })
            .then(() => {
                store.dispatch('setGdpr');
            })
            .catch(() => {
                // Token expired or invalid token
                localStorage.remove('token');
            });
    },

    /**
     * Check first contract is mobile or tv and redirect user to that page
     *
     */
    checkFirstContractType() {
        api.get('/api/me')
            .then((response) => {
                const firstContract = response.data.body.user.contracts[0];

                if (!firstContract || firstContract.isMobile) {
                    router.replace('/');
                } else {
                    router.replace('/tv');
                }
            })
            .catch(() => {
                // Token expired or invalid token
                localStorage.remove('token');
            });
    },

    /**
     * Set user data when user activate tariff, bundles..
     * @param context
     */
    setUserDataAfterUpdating(context) {
        api.get('/api/me')
            .then((response) => {
                context.commit('STORE_MAIN_USER_DATA', {
                    data: response.data.body.user,
                });
            });
    },
};
